<template>
  <div class="chat">
    <ShopNavBar title="客服" />

    <div class="scroll" id="scroll">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
        finished-text="没有更多了" @load="onLoad">
        <q-chat-message :name="showName(item)" avatar="@/assets/img/logo.png" :text="[item.msgcontent]"
          :stamp="item.mdate" :sent="item.fuserid == us.userid" v-for="(item, index) in list" :key="index">
          <div v-html="item.msgcontent"></div>
        </q-chat-message>
      </van-list>
    </div>


    <div class="footer">
      <q-input filled dense v-model="content" label="请输入内容">
        <template v-slot:before>
          <q-icon name="image"></q-icon>
        </template>
        <template v-slot:after>
          <q-btn round dense flat icon="send" @click="sendMsg" />
        </template>
      </q-input>
    </div>
  </div>
</template>
  
<script>
import { ref } from 'vue'
import { Toast, List } from 'vant';

export default {
  components: {
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      type: "对话消息",
      list: ref([]),
      receiver: ref(""),
      content: ref(""),

      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.$route.query.receiver) {
      this.receiver = this.$route.query.receiver
    }
    this.$ws.connection.on("GetToUser", (val) => {
      console.log(val)//收到新消息提醒
      this.unReadList()//获取未读消息
    })
    this.getList()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getList()
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    showName(e) {
      if (e.fuserid == this.us.userid) {
        return this.abbr(this.us.userid, 4, 4)
      } else {
        return this.abbr(e.fuserid, 4, 4)
      }
    },
    toBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$nextTick(() => {
            var ele = document.getElementById("scroll")
            ele.scrollTo(0, ele.scrollHeight);
          })
        }, 500)
      })
    },
    unReadList() {
      //获取未读消息
      let _this = this
      _this.$request.post(
        "api/MsgHub/UnReadList",
        {
          userid: _this.us.userid,
          sender: _this.us.userid,
          receiver: _this.receiver
        },
        (res) => {
          console.log("unReadList", res.data)
          if (res.data.code == 100) {
            _this.list.push(...res.data.data)
            _this.toBottom()
          }
        }
      )
    },
    getList() {
      //获取历史消息
      let _this = this
      _this.$request.post(
        "api/MsgHub/List",
        {
          userid: _this.us.userid,
          sender: _this.us.userid,
          receiver: _this.receiver,
          pageIndex: _this.pageIndex,
          pageSize: 20
        },
        (res) => {
          _this.loading = false
          console.log("getList", res.data)
          if (res.data.code == 100) {
            let data = res.data.data
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.list.push(...data)
            _this.toBottom()
          }
        }
      )
    },
    sendMsg() {
      //发送消息
      let _this = this
      if (_this.content == '') {
        Toast('内容不能为空');
        return
      }
      _this.$request.post(
        "api/MsgHub/SendMsg",
        {
          userid: _this.us.userid,
          sender: _this.us.userid,
          receiver: _this.receiver,
          type: _this.type,
          content: _this.content
        },
        (res) => {
          console.log("sendMsg：", res.data)
          if (res.data.code == 100) {
            _this.content = ""
            _this.list.push(res.data.data)
            _this.toBottom()
          }
        }
      )
    },
  }
}
</script>
  
<style scoped>
.chat {
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.scroll {
  padding: 10px;
  height: calc(100vh - 105px);
  overflow-y: auto;
}

.footer {
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
</style>